<template>
  <div id="errorPage">
    <h1>404 NOT FOUND!</h1>
    <h3>Please Check Your Address!</h3>
    <el-button type="primary" round style="width: 200px" @click="goHome">返回首页</el-button>
  </div>
</template>

<script>
export default {
  name: 'ErrorPage',
  methods: {
    goHome () {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style scoped>
#errorPage {
  padding: 100px;
  text-align: center;
}
</style>
